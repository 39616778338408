import {Helmet} from 'react-helmet-async';

const prefixShort = 'Ezi - ';
const defaultTitle = 'Ezi Agency Portal';
const TitleTag =({title,ignorePrefix=false,usedefault=false})=>{
  if(usedefault){
    return (
      <Helmet>        
        <title>{defaultTitle}</title>        
      </Helmet>
    )
  }
  if(ignorePrefix){
    return (
      <Helmet>        
        <title>{title}</title>        
      </Helmet>
    )
  }
  return (
    <Helmet>        
      <title>{`${prefixShort}${title}`}</title>        
    </Helmet>
  )
};

export default TitleTag;