import React, {useState} from 'react';
import {Drawer} from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import Menu from '../Menu';
import './style.scss';

export default () => {
  const [drawerState, setter] = useState(false);
  function openDrawer() {
    setter(true);
  }

  function closeDrawer() {
    setter(false);
  }
  return (
    <React.Fragment>
      <MenuOutlined style={{color: '#ffffff', fontSize:'23px'}} className='align-middle my-3 d-lg-none' onClick={openDrawer} />
      <Drawer
        title="Ezi"
        placement='left'
        closable={false}
        onClose={closeDrawer}
        open={drawerState}
        className='template-drawer'
      >
        <Menu />
      </Drawer>
    </React.Fragment>
  );
}
