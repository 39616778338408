import MainRoutes from './Routes';
import { GlobalProvider } from './Context';
import { QueryClient, QueryClientProvider } from 'react-query'
import {Helmet, HelmetProvider} from 'react-helmet-async';
// css
//import 'antd/dist/antd.css';
// import './theme/less/dist.css';
import 'antd/dist/antd.min.css';

import './theme/assets/include/scss/theme.scss';
import './theme/assets/vendor/hs-megamenu/src/hs.megamenu.css';
import './theme/assets/vendor/font-awesome/css/fontawesome-all.min.css';
import './theme/assets/vendor/flaticon/_flaticon.scss';
import './theme/rewrite.scss';
import { useDeviceSelectors } from 'react-device-detect';

import MobileWarning from './component/MobileWarning'
const queryClient = new QueryClient();

const App = () =>{
  const [selectors, data] = useDeviceSelectors(window.navigator.userAgent)
  const { isMobile } = selectors;
  if(isMobile){
    return (
      <>
        <MobileWarning />
      </>
    )
  }
  return (    
    <>
      <HelmetProvider>
        <Helmet>        
          <title>Ezi Agency Portal</title>        
        </Helmet>
        <QueryClientProvider client={queryClient}>
          <GlobalProvider>
            <MainRoutes />        
          </GlobalProvider>      
        </QueryClientProvider>
      </HelmetProvider>
    </>    
  );
}

export default App;
