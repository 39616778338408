import React from 'react';
import moment from 'moment';
import List from './List'

import {Form, Input, Modal, DatePicker, Button} from 'antd';
import './style.scss';


export class GalleryExplorer extends React.Component {
  formRef = React.createRef();

  state = {
    visible: false,
    filterData: {},
    selectedItems: []
  };

  selectedItemToggle = (itemId) => {
    const itemKey = this.state.selectedItems.indexOf(itemId);
    this.setState(prevState => {
      if(itemKey >= 0){
        prevState.selectedItems.splice(itemKey, 1); //remove
      }else{
        prevState.selectedItems.push(itemId); //add
      }
      return {
        selectedItems: prevState.selectedItems
      }
    });
  };

  handleOk = () => {
    this.props.onImport(this.state.selectedItems);
    this.props.onClose();
  };

  handleSubmit = (values) => {
    if(values.date) {
      values.uploaded_at = moment(new Date(values.date.valueOf())).format('Y-MM-DD');
    }

    values.type='image,floorplan,headshot';
    this.setState({
      filterData: values,
      elements: []
    });
    // e.preventDefault();
    // this.props.form.validateFields((err, values) => {
    //   if (!err) {
     
    //   }
    // });
  };

  disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  render() {
    // const {getFieldDecorator} = this.props.form;

    return (
      <Modal
        className='gallery-explorer-component'
        title="Import from gallery"
        // onOk={this.handleOk}
        open={true}
        onCancel={this.props.onClose}
        okText="Import"
        cancelText="Cancel"
        onOk={() => {
          this.handleOk();
          // this.formRef.current
          //   .validateFields()
          //   .then(values => {              
          //     this.handleOk(values);
          //   })
          //   .catch(info => {
          //     console.log('Validate Failed:', info);
          //   });
        }}
      >
        <div className="row">
          <div className="col-12 col-md-4 form-control-integrated">
            <Form ref={this.formRef} name='gallery_explorer' onFinish={this.handleSubmit}>
              <div className="form-group pt-2">
                <label className="form-label">Search By Name</label>
                <Form.Item name='name'>
                  <Input />
                </Form.Item>
                {/* {getFieldDecorator('name', {})(                )} */}
              </div>
              <div className="form-group pt-2">
                <label className="form-label">Search By Tag</label>
                <Form.Item name='tag' >
                  <Input />
                </Form.Item>
                {/* {getFieldDecorator('tag', {})(                )} */}
              </div>
              <div className="form-group pt-2">
                <label className="form-label">Search By Date</label>
                <Form.Item name='date'>
                  <DatePicker disabledDate={this.disabledDate} />
                </Form.Item>
                {/* {getFieldDecorator('date', {})(                )} */}
              </div>
              <div className="form-group pt-2">
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit">
                    Filter</Button>
              </div>
            </Form>
          </div>
          <div className="col-12 col-md-8 pl-0">
            <List
              filterData={this.state.filterData}
              selectedItemToggle={this.selectedItemToggle}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default GalleryExplorer;

