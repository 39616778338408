import React, { useState } from 'react';
import { message } from 'antd';
import * as orderApi from 'services/api/order';
import { browserRoutes } from 'consts/browserRoutes';
import NewArtWorkPopup from '../NewArtWork/Popup';
import SelectArtWorkPopup from '../SelectArtWork/Popup';
import EditOrderPopup from '../EditOrderPopup';
import { editJob } from 'services/api/printUI';
import history from 'utils/history';
import PrepositionalAndInstruction from '../PrepositionalAndInstruction';
import OrderItemComponent from './Component';
import ProccesDoneArtworkDetails from 'component/propertyDashboard/Common/Order/ArtworkEditor/Done/ProccesDoneArtworkDetails';
import { useCampaignContext } from '../../CampaignContext';
import './style.scss';
import { useNavigate } from 'react-router-dom';


export const statusTypeClasses = {
  green: 'btn-soft-success',
  red: 'btn-soft-danger',
  yellow: 'btn-soft-warning',
  gray: 'btn-soft-github'
};

const OrderItem = props => {
  const navigate = useNavigate();
  const { doRefresh = () => {console.log('Do Refresh Is not implmented')} } = props;
  const [deleting, setDeleting] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const {reloadHeader,campaignType} = useCampaignContext();


  const removeOrderHandler = () => {
    setDeleting(true);
    orderApi.orderRemoveAPI(props.data['_id'])
      .then(() => {
        message.success('Order Deleted Successfully');
        if(reloadHeader){
          reloadHeader();
        }
        // getCampaign(props.propertyId);
        doRefresh();
      })
      .catch(() => {
        message.error('Delete Order Error');
      })
      .finally(() => setDeleting(false));
  };

  const cancelOrder = () => {
    setCanceling(true);
    orderApi
      .orderCancel(props.data['_id'])
      .then(() => {
        message.success('Order Canceled Successfully');
        if(reloadHeader){
          reloadHeader();
        }
        doRefresh();
      })
      .catch(() => {
        message.error('Cancel Order Error');
      })
      .finally(() => setCanceling(false));
  };

  const requestForEdititng = (campaignId, orderId, jobId) => {
    editJob(props.data['_id']).then(res => {
      const {
        result: { url },
        // message,
        // success
      } = res.data;
      const target = browserRoutes.campaign.dashboard.residential.artworkEditor(
        campaignId,
        props.data['_id'],
        campaignType,
        encodeURI(jobId),
        encodeURIComponent(url),        
      );
      navigate(target);
    });
  };
  return (
    <>
      <OrderItemComponent
        propertyId={props.propertyId}
        campaignId={props.campaignId}
        isPackage={props.isPackage}
        doRefresh={doRefresh}
        presentMode={false}
        deleting={deleting}
        canceling={canceling}
        history={history}
        data={props.data}      
        removeOrder={removeOrderHandler}
        cancelOrder={cancelOrder}
        parentSelectHandler={props.parentSelectHandler}
        selectedAsParent={props.selectedAsParent}
        browserRoutes={browserRoutes}
        NewArtWorkPopup={NewArtWorkPopup}
        SelectArtWorkPopup={SelectArtWorkPopup}
        EditOrderPopup={EditOrderPopup}
        onEditSelectedTemplate={(campaignId, orderId, jobId) => {
          requestForEdititng(campaignId, orderId, jobId);
        }}
        getPreview={() => (
          <ProccesDoneArtworkDetails
            orderId={props.data._id}
            visibleContinueButton={false}
            visibleCongrats={false}
            asetPreviewData={props.data.assetId}
            downloadable={props.data.product.downloadable}
            order={props.data}
            campaignType={campaignType}

          />
        )}
        PrepositionalAndInstruction={PrepositionalAndInstruction}
        onSelectToDispatch={props.onSelectToDispatch}
      />
    </>
  );
};

export default OrderItem;
