import React,{useEffect} from 'react';
// import client1Logo from 'theme/assets/svg/clients-logo/stripe.svg';
// import backgroundImg from 'theme/assets/svg/components/abstract-shapes-20.svg';
import AuthLockService from 'Auth/AuthLockService';
import qs from 'query-string';
import { useLocation } from 'react-router';
import './style.css'
import VFXlogoImage from 'theme/assets/img/supplier/VFX_Connect_Logo.jpg';
import LogoImage from 'theme/assets/img/ezi-Logo.png'
const LoginPage =()=>{  
  const location = useLocation();
  const queryStringValues = qs.parse(location.search);
  const { supplier = '' } = queryStringValues;
  useEffect(() => {        
    let lock = new AuthLockService(); 
    lock.initLoginContainer();
    lock.lock.show({rememberLastLogin:true});
  }, []);

  const getLogoURIBaseOnSupplierNameFromQueryString = () => {
    switch (supplier.toLowerCase()) {
    case 'vfx':
      return VFXlogoImage;
    default:
      return LogoImage;
    }
  };

  return (
    <>
      {/* <div className='maintenance-banner'>
        System is in maintenance mode, some features may not work as expected.
      </div> */}
      <div className="login-container">
        <div className='card-sm shadow-lg' style={{width:'30rem'}}>
          
          <div className="card-body">
            <div className='text-center'>
              <img
                height="120px"
                src={getLogoURIBaseOnSupplierNameFromQueryString()}
                className="mt-2 w-auto"
                alt="Ezi Logo"
              />
            </div>
            <div className="">                  
              <div id="hiw-login-container"></div>
            </div>
          </div>
        </div>
      </div>
      <div className='template-footer'>
        <hr/>
        <div className="text-center my-2 py-2">
        © 2021 Ezi by Media Link Technologies
        </div>
      </div>
    </>
  )

}

export default LoginPage;