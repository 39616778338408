import { Modal } from 'antd';
import React from 'react';
import FacebookLoginPage from '../FacebookLogin';


const SocialNetworksModal = (props)=>{
  const {visible,setVisible,addAccountDone} = props;


  return (
    <>
      <Modal title="Select Social Network" open={visible}  onCancel={()=>{setVisible(false)}}
        footer={null}
      >
        <div className='text-center'>
          <FacebookLoginPage onDone={addAccountDone}/>
        </div>
      </Modal>
    </>
  )
}

export default SocialNetworksModal;