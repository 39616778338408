import React, {useState, useEffect, useCallback} from 'react';
import * as PropTypes from 'prop-types';
import {Modal, message} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ProductTypes from '../NewOrder/ProductTypes';
import Quantity from '../NewOrder/Quantity';
import Extra from '../NewOrder/Extra';
import {quantityGenerator} from '../NewOrder/utils';
import GeneralProductSelect from '../NewOrder/ServicesProductSelect/GeneralProductSelect';
import { useGlobalState } from 'Context';
import { useCampaignContext } from '../../CampaignContext';
import {getProducts, orderUpdatePro,getProductTypes } from 'services/api/order';


const EditOrderPopup = (props) => {
  
  const { advanceResidential } = useGlobalState();
  const {reloadHeader,campaignType} = useCampaignContext();
  const [productTypesItem, productTypesItemSetter] = useState([]);
  const [productTypeLoading, productTypeLoadingSetter] = useState(false);
  const [selectedProductType, selectedProductTypeSetter] = useState(0);
  const [extra, extraSetter] = useState([]);
  const [quantity, quantitySetter] = useState([]);
  const [orderData, orderDataSetter] = useState({});
  const [submitLoading, submitLoadingSetter] = useState(false);
  

  const getProductsList = useCallback((id) => {
    productTypeLoadingSetter(true);
    getProductTypes(id,advanceResidential,campaignType).then((data) => {
      if(data.data){
        productTypesItemSetter(data.data.results);
      }
      productTypeLoadingSetter(false);
    }).catch(() => {
      message.error('Getting product list failed');
      productTypeLoadingSetter(false);
    });
  }, [advanceResidential,campaignType])

  useEffect(()=>{    
    getProductsList(props.serviceId)  
  }, [getProductsList, props.serviceId]);

  const stepsState = {
    step2: () => {
      extraSetter([]);
      quantitySetter([]);
      orderDataSetter({});
    },
    step3: (product) => {
      quantitySetter([]);
      extraSetter([]);
      // timeout added to make reaction on Dom for consecutively set hook
      setTimeout(() => {
        quantitySetter(quantityGenerator(product));
        extraSetter(product.extra_options_with_defaults);
      }, 0);
    }
  };


  const handleProductTypeChange = (value) => {
    stepsState.step2();
    selectedProductTypeSetter(value);
  };

  const handleProductChange = (value) => {
    // orderDataSetter({product_id:value.id_obj});
    if(!props.isSingleProduct){
      orderDataSetter({product_id:value.id_obj});
    }else{
      orderDataSetter({ product_id: value.id_obj,quantity: 1  });      
    }
    stepsState.step3(value);
  };

  const handleQuantityChange = (value) => {
    orderDataSetter(Object.assign({}, orderData, {quantity:value}));
  };

  const handleExtraChange = (values) => {
    orderDataSetter(Object.assign({}, orderData, {extra:values}));
  };

  const okHandler = () => {
    submitLoadingSetter(true);
    const submitData = {
      campaign_type: campaignType,
      productId: orderData.product_id,
      extraIds: orderData.extra ? orderData.extra.join(',') : '',
      quantity: orderData.quantity,
    };

    orderUpdatePro(props.orderId, submitData).then((result) => {      
      submitLoadingSetter(false);
      reloadHeader();      
      props.onChangeItem(result.data.result)
      props.onCancel();
    }).catch(e => {
      message.error('Order update error');
      submitLoadingSetter(false);
      console.log(e);
    });
  };

  const saveText = (
    <React.Fragment>
      <LoadingOutlined size={24} spin className='mr-2' />
      Updating
    </React.Fragment>
  );

  return (
    <Modal
      className='custom-modal edit-order-modal'
      onCancel={props.onCancel}
      onOk={okHandler}
      okText={submitLoading ? saveText : 'Save'}
      cancelText={'Cancel'}
      open={true}
    >
      <div className="pt-3">
        <ProductTypes
          productTypes={productTypesItem}
          onChange={handleProductTypeChange}
          loading={productTypeLoading}
        />
      </div>
      <div className="pt-3">
        {selectedProductType !==0 && (
          <GeneralProductSelect
            apiData={{productTypeId:selectedProductType,campaignType,advanceResidential}}
            api={getProducts}
            productType={selectedProductType}
            onSelectProduct={handleProductChange}
            isSingleProduct={props.isSingleProduct}
            // nextStep={() => {}}
          />
        )}
      </div>
      <div className="pt-3">
        {!!quantity.length && !props.isSingleProduct && (
          <Quantity
            quantity={quantity}
            onChange={handleQuantityChange}
          />
        )}
      </div>
      {!!extra && !!extra.length && <Extra onChange={handleExtraChange} extra={extra} />}
    </Modal>
  );
};

EditOrderPopup.propTypes = {
  onCancel: PropTypes.func,
  orderId: PropTypes.string,
  serviceId: PropTypes.number,
  isSingleProduct: PropTypes.bool,
};

export default EditOrderPopup;
