import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { createRoot } from 'react-dom/client'

if (process.env.REACT_APP_ENVIRONMENT === 'Production') {
  Sentry.init({
    dsn:
      'https://4c09a190197d4af5a124230650537cbc@o478998.ingest.sentry.io/5522797',
    release: 'agency',
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
  });
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// document.getElementById('loading-bar').style.animationDuration = '0.2s';
// ReactDOM.render(
//   <App />,
//   document.getElementById('root')
// );
// setTimeout(() => {
//   ReactDOM.render(<App />, document.getElementById('root'));  
//   serviceWorker.unregister();
// }, 200);
