import React, { useEffect, useState } from 'react';
import { Form, Select, message, DatePicker, Input, Button } from 'antd';
import SingleFileUpload from 'component/form/SingleFileUpload';
import moment from 'moment';
import {
  collectionMaintenanceRequest,
  getOrder,
} from '../../../../../services/api/order';
import { addressMaker } from '../../../../form/Address/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProgressCard from 'component/common/ProgressCard';
import { useCampaignContext } from '../../CampaignContext';

const { TextArea } = Input;
const { Option } = Select;

const convertPrice = (price) =>
  parseInt(price) === parseFloat(price) ? parseInt(price) : price;

const  CollectionMaintenance = (props) =>{
  const {campaignDetail} = useCampaignContext();
  const { orderId} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [services, servicesSetter] = useState([]);
  const {pathname} = useLocation();
  let type = pathname.indexOf('/collection') >= 0 ? 'collections' : 'maintenances';

  let addressDetail;


  useEffect(() => {
    setLoading(true);
    getOrder(orderId).then((data) => data.data.result)
      .then((data) => {
        if (data.product[type] && !!data.product[type].length) {
          servicesSetter(data.product[type]);          
        }
      })
      .catch((e) => {
        console.log(e);
        message.error('Get order error');
      }).finally(()=>{
        setLoading(false);
      });
  }, [orderId, type]);

  
  if (campaignDetail) {
    const infoKey = `${campaignDetail.campaign_type.replace(/-/g, '_')}_info`;
    addressDetail = campaignDetail[infoKey]?.property.address;
  }

  

  const onOk = (values) => {    
    const dataToSubmit = {
      jobId: orderId,
      service: {
        serviceId: values.id,
        notes: values.notes,
        positionalImg: values['positional-image'],
      },
      dispatch: {
        address: addressDetail,
      },
    };
    var localFormat = 'YYYY-MM-DD[T]HH:mm:ss';

    const deliveryDate =values.date ? values.date.format(localFormat) : new Date();

    dataToSubmit.service.date = deliveryDate;
    dataToSubmit.dispatch.deliveryDate = deliveryDate;    
    setSubmitting(true);
    collectionMaintenanceRequest(dataToSubmit)
      .then(() => {
        message.success(`${type} Requested successfully`);
        navigate(-1);
      })
      .catch((e) => {
        console.log(e);
        message.error(`Request for ${type} error`);
      }).finally(()=>{
        setSubmitting(false);
      });
  };

  if (loading || !campaignDetail)
    return (
      <ProgressCard />
    );

  const disabledDate = (current) => {
    // Can not select days before today and today (after 12:00)  
    const cd = current.day();
    const isOfday = cd ===0;
    
    let todayOff=false;
    var isToday = current.isSame(new Date(), 'day');
    if(isToday) {      
      if(current.hour()>= 12){
        todayOff=true;
      }
    }
    return isOfday || current.startOf('day') < moment().startOf('day') || todayOff;
  };

  return (
    <div className="container pt-4">
      <div className="head pt-3 pt-sm-5">
        <h3 className="title pb-1 pb-sm-4 mb-0">Order {type}</h3>
      </div>
      <Form name="collection-maintenance" onFinish={onOk}>
        <label>Service</label>
        <Form.Item
          name="id"
          rules={[{ required: true, message: 'Please select a service' }]}
        >
          <Select size="large" placeholder="Select Service">
            {services.map((service, key) => (
              <Option value={service.id} key={key}>
                {service.name} - {convertPrice(service.price)}$
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          <label>Date</label>
          <Form.Item
            name="date"
            rules={[{ required: true, message: 'Please select a date' }]}
          >
            <DatePicker
              size="large"
              format="DD/MM/YYYY"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </div>
        <label>Note</label>
        <Form.Item
          name="notes"
          rules={[{ required: true, message: 'Note is required' }]}
        >
          <TextArea rows={3} />
        </Form.Item>
        <label>Address</label>
        <Form.Item
          name="addressDetail"
          initialValue={addressDetail ? addressMaker(addressDetail) : ''}
        >
          <Input
            
            disabled
            size="large"
          />
        </Form.Item>
            
        <label className="form-label">Positional Image</label>
        <Form.Item name="positional-image">
          <SingleFileUpload
            additionalData={{
              category: 'campaign',
              subcategory: 'positional',
              campaignId: campaignDetail.id
            }}
            // defaultImage={defaultPositionalImg}
            accept=".jpg,.png,.gif"
            fileType="image"
            placeholder={'Select a positional image'}
            addonBefore={<span className="fas fa-ruler-combined text-gray-600" />}
          />
        </Form.Item>
        <div className="d-flex flex-row-reverse">
          <Button type="primary" size="large" htmlType="submit" loading={submitting} disabled={submitting} >
                Order
          </Button>
          <Button
            type="dashed"
            size="large"
            className="mr-2"
            onClick={()=>{navigate(-1)}}
          >
                Cancel
          </Button>
        </div>
      </Form>
    </div>
    // </Container>
  );
}
export default CollectionMaintenance;
