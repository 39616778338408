import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import ProgressCard from 'component/common/ProgressCard';
import { setConfigInfo } from '~/Context/actions';
import { useGlobalDispatch } from '~/Context';
import { getProjectConfig } from 'services/api/config';
import auth0Client from 'Auth/AuthClient';
import * as Sentry from '@sentry/react';
import Footer from 'component/Template/Footer';
import { message } from 'antd';

const CallbackPage = ()=>{
  const dispatch = useGlobalDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    auth0Client.handleAuthentication().then(()=>{
      

      getProjectConfig()
        .then((data) => data.data)
        .then((data) => {          
          if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
            Sentry.setUser({
              name: `${data.user.first_name} ${data.user.last_name}`,
              email: data.user.email,
            }
            );
          }

          // if (data.agency?.agency_color !== '#0AB5EA') {
          //   require('theme/assets/include/scss/theme.scss');
          //   require('theme/less/dist.css');
          // } else {
          //   require('theme/assets/include/scss/theme-0AB5EA.css');
          //   require('theme/less/dist-0AB5EA.css');
          // }
          if(data.user.blocked){
            auth0Client.signOut();    
            // logout(dispatch);
          }
          
          setConfigInfo(dispatch, data);       
          setTimeout(() => {
            navigate('/');
          }, 500);         
        })
        .catch(() => {
          message.error('User does not exist');
          setTimeout(() => {
            auth0Client.signOut();
          }, 500);
          // require('theme/assets/include/scss/theme.scss');
          // require('theme/less/dist.css'); //cascader bug
        });
    }).catch(err=>{
      message.error(err);
      auth0Client.signOut();
    });
      
  }, []);

  return (
    <>
      <ProgressCard />
      <Footer />
    </>
  );  
}

export default CallbackPage;