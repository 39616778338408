import React, { useCallback, useState ,useEffect} from 'react';
import {  Modal, message, Empty, Button } from 'antd';
import { CheckCircleOutlined,RightOutlined,LeftOutlined } from '@ant-design/icons';
import history from 'utils/history';
import { createJob, getProductTemplates } from 'services/api/printUI';
import StyledDiv from './popup-style';
import { useCampaignContext } from '../../CampaignContext';
import { useGlobalState } from 'Context';
import { browserRoutes } from 'consts/browserRoutes';
import axios from 'axios';
import Loader from 'component/common/Loader';
import './style.scss';
import { useNavigate } from 'react-router-dom';

const ArtworkItem = ({ image, name, select, selected, colSize }) => {
  return (
    <div className={`${colSize} p-2`} onClick={select}>
      <div className='item'>
        <span className='item__name'>{name}</span>
        <img className='embed-responsive' src={image} alt={name} />
        {selected && (
          <div className='selected'>
            <CheckCircleOutlined />
          </div>
        )}
      </div>
    </div>
  );
};


const Popup = props => {
  const navigate = useNavigate();
  const {campaignType} = useCampaignContext();
  const [templates,setTemplate] = useState([]);
  const [agents,setAgents] = useState([]);
  // const [agentsOrdered,setAgentsOrdered] = useState([]);
  const { agency } = useGlobalState();  
  const [jobMaking, jobMakingSetter] = useState(false);
  const [selectedItem, selectedItemSetter] = useState();
  const [loading, setLoading] = useState(true);
  const [mode, setMode] = useState(0);
  const selectItem = id => selectedItemSetter(id === selectedItem ? null : id);
  // let agentsOrdered=[];
  
  useEffect(() => {
    const templateRequest = getProductTemplates(props.productId);    
    setLoading(true);
    axios.all([templateRequest]).then(axios.spread((...responses) => {
      const responseTemplate = responses[0].data.result;
      
      
      setTemplate(responseTemplate);
      // setAgents(responseAgents);
      // setAgentsOrdered(responseAgents);

      if(responseTemplate.length===1){
        goTheJob(responseTemplate[0]);
      }
      setLoading(false);
      // use/access the results 
    })).catch(() => {
      // react on errors.
    })
  }, []);

  // const setOrderedAgents =(items)=>{    
  //   setAgents(()=>items)
  // }

  const onOk = () => {
    if(mode===0 && agents.length>1){
      setMode(1);
    }else if(mode===1 || (mode===0 && agents.length<1)){      
      const selectedTemplate = templates.find(
        item => item['_id'] === selectedItem
      );
      goTheJob(selectedTemplate);
    }   
  };

  const goTheJob = useCallback(
    
    (selectedTemplate) => {

      const payload = {
        template: selectedTemplate.name,
        orderId: props.orderId,
        // orderedAgentIds: agents.map(x=>x.id),
        sizeCode: selectedTemplate.sizes[0].sizeCode,
        assetConfig: {
          // agencyId: selectedTemplate.agencyId[0],
          agencyId: agency.id,
          fileName: `${selectedTemplate.name} ${props.orderId}`,
          tags: selectedTemplate.tags.join(''),
          campaignId: '' + props.campaignId
        }
      };
      jobMakingSetter(true);
      // put agent id orderred: orderedAgentIds
      createJob(payload)
        .then(data => data.data)
        .then(data => {
          const jobId = data.result.jobId;
          const url = data.result.url;          
          const target = browserRoutes.campaign.dashboard.residential.artworkEditor(
            props.campaignId,
            props.orderId,
            campaignType,
            encodeURI(jobId),
            encodeURIComponent(url),            
          );
          navigate(target);
        })
        .catch(e => {
          message.error('Error in preparing Template (Job failed)');
          jobMakingSetter(false);
          console.log(e);
        });
    },
    [props.campaignId, props.orderId,agents]
  );
  
  const Header = ()=>{
    if(templates.length<1) return null;
    if(mode===1){
      return (
        <Button type="link" onClick={()=>{setMode(0)}}><LeftOutlined /> Back to template selection</Button>
      )
    }
    return null;
  }

  const TemplatesSection = ()=>{
    if(loading) return null;
    if(mode!==0) return null;
    return (
      <div className='row items'>
        <div className={selectedItem ? 'col-6' : 'col-6'}>
          <div className='row artwork-item-container'>
            {!templates.length && (
              <div className='text-center w-100'>
                <Empty />
              </div>
            )}
            {templates.map(template => {
              const isSelected = template._id === selectedItem;
              const imageURI = template.sizes[0].thumbnailUrl;

              return (
                <ArtworkItem
                  key={template._id}
                  image={imageURI}
                  name={template.name}
                  selected={isSelected}
                  select={() => selectItem(template._id)}
                  colSize={selectedItem ? 'col-12' : 'col-12'}
                />
              );
            })}
          </div>
        </div>
        {selectedItem && (
          <div className='col-6'>
            <img
              className='embed-responsive'
              src={
                templates.find(item => item['_id'] === selectedItem)
                  .sizes[0].thumbnailUrl
              }
              alt='Selected Template'
            />
          </div>
        )}
      </div>
    )
  }

  // const AgentSection = ()=>{
  //   if(loading) return null;
  //   if(mode!==1) return null;

  //   return (
  //     <AgentsList data={agents} agentSetter={setOrderedAgents} />
  //   )

  // }

  return (
    <Modal
      style={{ top: 20 }}
      title={mode===0?'Select Template':'Agent reordering'}
      onOk={onOk}
      onCancel={props.onCancel}
      className='select-artwork-popup'
      open={true}
      okText={
        <>
          {mode===0 && agents.length>1 && (
            <>
              Select Agent Ordering
              <RightOutlined />
            </>
          )}
          {mode===0 && agents.length<1 && (
            <>
              Select and edit
              <RightOutlined />
            </>
          )}
          {mode===1  && (
            <>
              Select and edit
              <RightOutlined />
            </>
          )}
        </>
      }
      okButtonProps={{
        size: 'large',
        loading: jobMaking,
        disabled: !selectedItem
      }}
      cancelButtonProps={{ size: 'large' }}
    >      
      <StyledDiv>
        <Header />
        {loading && <Loader />}
        <TemplatesSection />
        {/* <AgentSection /> */}
      </StyledDiv>      
    </Modal>
  );
};

export default Popup;
