import React from 'react';
import './style.scss';

const Footer = () => {
  return(
    <div className='template-footer'>
      <hr/>
      <div className="text-center my-2 py-2">
      © 2021 Ezi by Media Link Technologies-v2.0
      </div>
    </div>
  );
};

export default Footer;