import React, { FC, useEffect, Dispatch, useState } from 'react';
import { Button, Dropdown, Menu, message, Spin, Skeleton } from 'antd';
import { LoadingOutlined ,PlusCircleOutlined} from '@ant-design/icons';
import { useImmerReducer } from 'utils/hooks/immer';
import {
  ReducerAction,
  Dates,
  StateReducerActions,
  ReducerActionPayloadForUpdateInsert
} from './types';
import { reducer } from './stateReducer';
import InspectionItem from './InspectionItem';
import AuctionItem from './AuctionItem';

import { getCampaign, updateCampaign } from 'services/api/campaign';
import { useFetchAPI } from 'services/api/useFetch';
import Wrapper from './style';
import { CampaignConsumer, useCampaignContext } from '../CampaignContext';

const getDateObjectFromStr = (
  dateStr: string
): ReducerActionPayloadForUpdateInsert => {
  const [date, start, end] = dateStr
    .replace('to', '')
    .replace(/ {2}/g, ' ')
    .split(' ');
  const endFinal = end?.replace('to', '').replace(/ /g, '');

  return { date, start, end:endFinal };
};

interface ChangesData {
  // date: any;
  dateString: string;
  type: string;
  kind: 'start' | 'end' | 'date';
  index: number;
}
interface ParamTypes {
  propertyId: string;
}
const InspectionAuction: FC = () => {    
  const {campaignDetail,updateHeader,cacheVersion} = useCampaignContext();
  const [saveing, setSaveing] = useState<boolean>();

  // const {data, isLoading,refetch} = useFetchAPI(
  //   getCampaign,
  //   campaignContext.campaignDetail.id
  // ) as any;
  
  const [dates, dispatchDates] = useImmerReducer(reducer, {
    auction: '',
    inspection_times: []
  }) as [Dates, Dispatch<ReducerAction>];

  useEffect(() => {    
    if (campaignDetail) {
      const inspection_times =
      campaignDetail.residential_real_estate_info.property.inspection_times || [];

      const auction = (campaignDetail.residential_real_estate_info.auction || '').replace(
        'T',
        ' '
      );

      dispatchDates({
        type: StateReducerActions.All,
        payload: {
          auction,
          inspection_times
        }
      });
    }
  }, [cacheVersion, dispatchDates]);

  const onSubmit = (): void => {
    if (saveing) {
      return;
    }
    const inspection_times: [string] = dates.inspection_times;
    let auction: string | null = dates.auction.replace(' ', 'T');
    auction = auction === '' ? null : auction;
    setSaveing(true);
    updateCampaign(campaignDetail.id, {
      residential_real_estate: {
        property: {
          inspection_times
        },
        auction
      }
    }).then(data=>data.data)
      .then((data) => {
        message.success('Saved');
        // refetch();
        updateHeader(data);
        setSaveing(false);
      })
      .catch(() => {
        message.error('Some error happend please try again!');
        setSaveing(false);
      });
  };

  const newItem = (type: string) => {
    const payload: ReducerActionPayloadForUpdateInsert = {
      date: new Date().toISOString().split('T')[0],
      start: '00:00',
      end: '01:00pm'
    };
    if (type === StateReducerActions.AddAuction) {
      delete payload.end;
    } else {
      payload.start = payload.start + 'am';
    }

    dispatchDates({
      type,
      payload
    });
  };

  const MenuOverlay = (
    <Menu>
      <Menu.Item key='inspectionMI' onClick={() => newItem(StateReducerActions.AddInspection)}>
        <span>Inspection</span>
      </Menu.Item>
      <Menu.Item key='auctionMI' onClick={() => newItem(StateReducerActions.AddAuction)}>
        <span>Auction</span>
      </Menu.Item>
    </Menu>
  );

  const changes = (data: ChangesData): void => {
    const { dateString, type, kind, index } = data;

    switch (type) {
    case 'Auction':
      {
        const dateObject = getDateObjectFromStr(dates.auction);
        dateObject[kind] = dateString;

        dispatchDates({
          type: StateReducerActions.UpdateAuction,
          payload: {
            index,
            ...dateObject
          }
        });
      }

      break;
    case 'Inspection':
      {
        const dateObject = getDateObjectFromStr(
          dates.inspection_times[index]
        );
        dateObject[kind] = dateString;
        dispatchDates({
          type: StateReducerActions.UpdateInspection,
          payload: {
            index,
            ...dateObject
          }
        });
      }

      break;
    }
  };
  const getAuctionItem = () => {
    if (!dates.auction) {
      return null;
    }
    const { date, start } = getDateObjectFromStr(dates.auction);

    return (
      <AuctionItem
        onChange={changes}
        defaultValue={{ date, start }}
        onRemove={() => {
          dispatchDates({
            type: StateReducerActions.RemoveAuction
          });
        }}
      />
    );
  };
  return (
    // <Container>
    <CampaignConsumer>
      {({isReadonly})  => (
      
        <Wrapper className="container pt-5 form-control-integrated">
          <h3 className="font-weight-medium d-inline-block mr-3">
          Inspection/Auction Details
          </h3>
          <>
            {isReadonly!==true && <>
              <Dropdown overlay={MenuOverlay}>
                <Button size="small" style={{ height: '32px' }} icon={<PlusCircleOutlined />}>
                add 
                </Button>
              </Dropdown>
              <Button
                className="submit-btn"
                onClick={onSubmit}
                disabled={saveing}
              >              
              Save
                {saveing && (
                  <>                  
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                    />
                  </>
                )}
              </Button>
            </>
            }
            {saveing ? (
              <>
                <Skeleton active />
                <Skeleton active />                
              </>
            ) : (
              <>
                {dates.auction && (
                  <>
                    <span className="title">Auction Time</span>
                    <div className="item-containers">{getAuctionItem()}</div>
                  </>
                )}
                {dates.inspection_times.length > 0 && (
                  <>
                    <span className="title">Inspection Times</span>
                    <div className="item-containers">
                      {dates.inspection_times.map(
                        (item: string, index: number) => {
                          const defaultValue = getDateObjectFromStr(
                            item
                          ) as ReducerActionPayloadForUpdateInsert;
                          return (
                            <InspectionItem key={index}
                              index={index}
                              onChange={changes}
                              defaultValue={defaultValue}
                              onRemove={() => {
                                dispatchDates({
                                  type: StateReducerActions.RemoveInspection,
                                  payload: {
                                    index
                                  }
                                });
                              }}
                            />
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        </Wrapper>
      )}
    </CampaignConsumer>
    // </Container>
  );
};

export default InspectionAuction;
